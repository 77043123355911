import { HeroText } from "../Hero/Hero";
import { SermonsHero } from "../ImageLibrary/imageLibrary";
import { ListingWithSearch } from "../../components/ListingWithSearch/ListingWithSearch";
import { usePublicContext } from "../PublicContext";
import { SermonInterface } from "../../components/Interfaces/Interfaces";
import { SermonCard } from "./SermonCard";
import { usePageUtils } from "../usePageUtils";

export const SermonsContainer = () => {
  const { sermons } = usePublicContext();
  usePageUtils().setTitleAndGroup("Sermons", "Get Plugged In");

  return (
    <div className="listing-search-with-padding">
      <HeroText
        img={[SermonsHero]}
        text={"Sermons"}
        align="top-left"
        width={4000}
        height={1778}
      />
      <ListingWithSearch
        elementName="sermons"
        items={
          sermons?.filter(
            (sermons) =>
              sermons.published_on.Time &&
              sermons.published_on.Time <= new Date().toISOString()
          ) ?? []
        }
        card={buildSermonCard}
        searchFields={["title", "main_passage"]}
        searchBySpeakerSeries
      />
    </div>
  );
};

const buildSermonCard = (sermon: SermonInterface) => (
  <SermonCard key={sermon.slug + sermon.id} data={sermon} />
);

import {
  Bulletin,
  SermonInterface,
} from "../../components/Interfaces/Interfaces";
import { usePublicContext } from "../PublicContext";

const getLatestPublishedBulletin = (
  sermons: SermonInterface[],
  bulletins: { [p: string]: Bulletin }
) =>
  sermons
    .filter((x) => x.sermon_date.Valid)
    .sort((a, b) => (a.sermon_date.Time < b.sermon_date.Time ? 1 : -1))
    .map((x) => bulletins[x.id + "-published"])
    .filter((x) => x != null)
    .shift();

export const useLatestPublishedBulletin = () => {
  const { sermons, bulletins } = usePublicContext();

  return getLatestPublishedBulletin(sermons ?? [], bulletins);
};

import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenNib } from "@fortawesome/pro-light-svg-icons";
import "./CTA.scss";

export const CTA = (props: { children: ReactNode }) => (
  <div className="cta">
    <FontAwesomeIcon icon={faPenNib} />
    {props.children}
  </div>
);

import "./Post.scss";
import { Link, useLocation } from "react-router-dom";
import { getPathPrefix, prettyDate } from "../../../utils";
import {
  OrderedFile,
  SpeakerSeriesData,
  SQLNullString,
} from "../../Interfaces/Interfaces";
import { DisplayFiles } from "../../DisplayFiles/DisplayFiles";
import { SeriesImage } from "../../SeriesImage";
import Scroll from "react-scroll";
import { ReactElement, useEffect } from "react";
import { BackBtn } from "./BackBtn";

const PostDetails = (props: {
  postImage?: SQLNullString;
  series?: SpeakerSeriesData;
  title: string;
  sidebar?: ReactElement | ReactElement[];
}) => (
  <div className="post-details-main">
    {props.postImage?.Valid && (
      <div style={{ flex: 1 }}>
        <SeriesImage data={props.postImage} />
      </div>
    )}
    <div style={{ flex: 2 }}>
      <SermonSeries series={props.series} />
      <h2 className="title">{props.title}</h2>
      {props.sidebar}
    </div>
  </div>
);

export const Post = (props: {
  date?: string;
  title: string;
  sermonSeries?: SpeakerSeriesData;
  files?: OrderedFile[];
  postImage?: SQLNullString;
  sidebar?: ReactElement | ReactElement[];
  children: any;
}) => {
  useEffect(() => {
    Scroll.scroller.scrollTo("post", {
      smooth: true,
      duration: 300,
      offset: window.innerWidth > 720 ? -140 : -80,
    });
  }, [props.title]);

  return (
    <div className="post">
      <Scroll.Element name="post" />
      <BackBtn />
      <div className="post-details">
        {props.date != null && (
          <div className="date">{prettyDate(props.date)}</div>
        )}

        <PostDetails
          postImage={props.postImage}
          series={props.sermonSeries}
          title={props.title}
          sidebar={props.sidebar}
        />
      </div>
      <div className="post-content">{props.children}</div>
      {props.files && <DisplayFiles files={props.files} />}
    </div>
  );
};

const SermonSeries = (props: { series?: SpeakerSeriesData }) => {
  const { pathname } = useLocation();
  const basePath = getPathPrefix(pathname);
  if (!props.series || props.series.name === "") return <></>;
  const { name, id } = props.series;

  return (
    <Link to={basePath + "?series=" + id} className="series">
      {name}
    </Link>
  );
};

import { usePageUtils } from "../usePageUtils";
import { HeroText } from "../Hero/Hero";
import { SermonsHero } from "../ImageLibrary/imageLibrary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faClock,
  faDove,
  faFamily,
  faHandsHoldingHeart,
  faPeopleGroup,
  faPrayingHands,
  faRingsWedding,
  faWater,
} from "@fortawesome/pro-light-svg-icons";
import { ReactNode } from "react";
import "./ForMembers.scss";
import { EmailLink } from "../../components/contact-components/EmailLink/EmailLink";
import { BFECWhatsApp } from "../../components/contact-components/WhatsApp/WhatsApp";
import { WebLink } from "../../components/contact-components/WebLink/WebLink";
import { Phone } from "../../components/contact-components/Phone/Phone";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";
import { CTA } from "../CTA/CTA";

const scrollToHash = (hash: string) => {
  Scroll.scroller.scrollTo(hash.substring(1), {
    duration: 600,
    smooth: true,
    offset: window.innerWidth > 720 ? -110 : -65,
  });
};

export const ForMembers = () => {
  const { setTitleAndGroup } = usePageUtils();

  setTitleAndGroup("For Members", "General");

  return (
    <div>
      <HeroText
        img={[SermonsHero]}
        width={4000}
        height={1778}
        text="For Members"
        align="bottom-left"
      />
      <div className="content-holder" id="for-members">
        <MembersNavigation />
        <MemberSection title="Babies & Children" icon={faFamily}>
          <ul>
            <li>
              <strong>Birth:</strong> Share the joy with us by emailing to{" "}
              <EmailLink to="bfec" /> or whatsapping to <BFECWhatsApp />
            </li>
            <li>
              <strong>Children’s Dedication:</strong> Held twice a year, the
              dedication of each child, by one or both parents, is done during a
              church service, in the presence of God, and affirmed by the
              congregation. Parents dedicating their child to our Lord not only
              seek God’s blessings and guidance in the upbringing of their child
              but also the loving accountability and fellowship of church
              members.
            </li>
          </ul>
          <Frequency>April & October (twice a year)</Frequency>
          <CTA>
            Sign up at <WebLink link="bfec.sg/dedication" />
          </CTA>
        </MemberSection>
        <MemberSection title="Baptism & Membership" icon={faWater}>
          <p>
            Baptism is a key step in the discipleship process in a believer's
            life... one that will further strengthen the believer's faith as you
            obey God in identifying with Christ. Attend the 4 on-site
            baptism/transfer classes to prepare yourself & clarify your
            convictions as we learn more about how to grow & serve together in
            this BFEC community. The classes will be conducted on Sunday
            mornings 8.30–10 am.
          </p>
          <Frequency>3 times a year</Frequency>
          <CTA>
            Sign up at <WebLink link={"bfec.sg/baptism"} /> or{" "}
            <WebLink link={"bfec.sg/transfer"} />
          </CTA>
        </MemberSection>
        <MemberSection title="Small Groups" icon={faPeopleGroup}>
          <p>
            How does a church grow bigger without growing colder? By growing
            “smaller”! Indeed, the simple, proven answer is through cell groups.
            When you and I are in one that honours Him, we will not only belong
            to a family but behave like one.
          </p>
          <CTA>
            Sign up at <WebLink link={"bfec.sg/CG-application"} />
          </CTA>
        </MemberSection>
        <MemberSection title="Marriage" icon={faRingsWedding}>
          <h3>The Pre-Marriage Course (TPMC) - First Things First</h3>
          <p>
            There are many things on the minds of a Christian couple preparing
            for Holy Matrimony. From selecting a suitable date to a suitable
            life verse. From securing a place to call home to timing the "ROM"
            date. However, the most important first step, before making bookings
            of any sort is to undergo a Pre-Marriage Course. Studies show the
            benefits when a couple takes a step back to learn to apply the
            fundamentals of what the marriage covenant entails. And Christians
            are not exempt from the danger of marital breakdown. Thankfully,
            courses like this are designed to help provide godly and helpful
            handles for important conversations for the couple, and with a
            mentoring couple. You may also get to know other couples preparing
            for marriage who will also serve as your spiritual community.
          </p>
          <p>TPMC is a 5-session course intended for couples:</p>
          <ul>
            <li>
              attending BFEC, both of whom are baptised believers or are making
              preparations for baptism at least 6 months before marriage.
            </li>
            <li>
              intending to marry minimally a year later (to facilitate an
              unhurried and meaningful time)
            </li>
          </ul>
          <Frequency>January–June and July–December (twice a year)</Frequency>
          <CTA>
            Sign up at <WebLink link={"bfec.sg/pmc"} />
          </CTA>
          Registration for courses must be submitted by 31 July for the
          July-December run, and by 31 January for the January-June run.
          <h3>Planning to hold your Wedding Ceremony in BFEC</h3>
          <p>
            Refer to <WebLink link={"bfec.sg/guidelines-wedding"} />
          </p>
          <CTA>
            Sign up at <WebLink link={"bfec.sg/weddingapplication"} />
          </CTA>
        </MemberSection>
        <MemberSection title="Prayer Requests" icon={faPrayingHands}>
          <p>
            Email to <EmailLink to="bfec" /> or whatsapp to <BFECWhatsApp /> so
            we can alert the F.I.R.E team to pray with you
          </p>
          <p>
            <b>F</b>rankel <b>I</b>ntercessors <b>R</b>eady <b>E</b>verywhere &
            Everytime
          </p>
        </MemberSection>
        <MemberSection title="Share a Thanksgiving" icon={faHandsHoldingHeart}>
          <p>
            Submit your thanksgiving to <WebLink link={"bfec.sg/givethanks"} />{" "}
            or whatsapp to <BFECWhatsApp />
          </p>
        </MemberSection>
        <MemberSection title="Homecalls & Bereavements" icon={faDove}>
          <p>
            The church would like to come alongside you during this time of
            bereavement.
          </p>
          <ul>
            <li>Inform your CGL and</li>
            <li>
              Drop us a call at <Phone />
            </li>
          </ul>
        </MemberSection>
      </div>
    </div>
  );
};

const MembersNavigation = () => {
  return (
    <div id="members-nav">
      <MembersNavLink to="Babies & Children" />
      <MembersNavLink to="Baptism & Membership" />
      <MembersNavLink to="Small Groups" />
      <MembersNavLink to="Marriage" />
      <MembersNavLink to="Prayer Requests" />
      <MembersNavLink to="Share a Thanksgiving" />
      <MembersNavLink to="Homecalls & Bereavements" />
    </div>
  );
};

const headerToLink = (header: string) =>
  header.toLowerCase().replace(" & ", "-").replaceAll(" ", "-");

const MembersNavLink = (props: { to: string }) => {
  const hash = "#" + headerToLink(props.to);
  return (
    <Link onClick={() => scrollToHash(hash)} to={hash}>
      {props.to}
    </Link>
  );
};

const MemberSection = (props: {
  title: string;
  icon: IconDefinition;
  children: ReactNode;
}) => (
  <div id={headerToLink(props.title)} className="member-section">
    <div className="row header">
      <div className="section-icon">
        <FontAwesomeIcon icon={props.icon} size="xl" />
      </div>
      <h2>{props.title}</h2>
      <Link
        onClick={() => scrollToHash("#for-members")}
        to="#for-members"
        className="to-top"
      >
        ^ Back to top
      </Link>
    </div>
    <div className="section-body">{props.children}</div>
  </div>
);

const Frequency = (props: { children: ReactNode }) => (
  <div className="frequency">
    <FontAwesomeIcon icon={faClock} />
    {props.children}
  </div>
);

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLongArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { getLatestSermon, prettyDate } from "../../utils";
import { MinistriesData } from "../AboutUs/Ministries/PeopleMinistries/MinistriesData";
import { MinistryLink } from "../AboutUs/Ministries/PeopleMinistries/MinistryLink";
import { Hero } from "../Hero/Hero";
import {
  FrontpageHeroes,
  FrontpagePeopleImage,
  FrontpageTestimoniesImage,
} from "../ImageLibrary/imageLibrary";
import { usePublicContext } from "../PublicContext";
import "./Frontpage.scss";
import { easter } from "date-easter";
import { SermonInterface } from "../../components/Interfaces/Interfaces";
import { format } from "date-fns";
import { usePageUtils } from "../usePageUtils";

interface ButtonProp {
  children: ReactNode;
  link: string;
}

interface SegmentProp {
  title: string | ReactElement;
  buttonTitle: string;
  link: string;
  image?: string;
  width?: number;
  height?: number;
}

const FrontpageButton = (props: ButtonProp) => (
  <Link to={props.link} className="btn btn-primary">
    {props.children}
  </Link>
);

export const FrontpageSegment = (props: SegmentProp) => {
  const { title, buttonTitle, link, image, width, height } = props;

  if (image && width && height) {
    return (
      <Hero img={[image]} width={width} height={height}>
        <div className="frontpage-segment">
          <div className="stylised-header hero-text">{title}</div>
          {buttonTitle !== "" && (
            <FrontpageButton link={link}>{buttonTitle}</FrontpageButton>
          )}
        </div>
      </Hero>
    );
  }

  return (
    <section className="frontpage-segment white-card">
      <div className="normal-header">{title}</div>
      <FrontpageButton link={link}>{buttonTitle}</FrontpageButton>
    </section>
  );
};

const MinistriesSegment = () => {
  return (
    <section className="frontpage-segment white-card">
      <section className="ministry-select">
        <div className="row">
          {MinistriesData.slice(0, 6).map((ministry, i) => (
            <MinistryLink ministry={ministry} key={i} />
          ))}
        </div>
        <div className="row">
          <Link to="/our-community/ministries" id="frontpage-ministries-all">
            See All Ministries{" "}
            <FontAwesomeIcon icon={faLongArrowRight as IconProp} />
          </Link>
        </div>
      </section>
    </section>
  );
};

const CardButton = (props: ButtonProp) => {
  const { children, link } = props;

  if (link.startsWith("http")) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-border-white"
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={link} className="btn btn-border-white">
      {children}
    </Link>
  );
};

function calculateServiceTitle(sermon: SermonInterface) {
  const dateObj = new Date(sermon.sermon_date.Time);

  if (dateObj.getMonth() === 11 && dateObj.getDate() === 25) {
    return "Christmas";
  }

  const easterDate = easter(dateObj.getFullYear());
  const easterDateObj = new Date(
    easterDate.year,
    easterDate.month - 1,
    easterDate.day
  );
  if (
    dateObj.getMonth() === easterDateObj.getMonth() &&
    dateObj.getDate() === easterDateObj.getDate()
  ) {
    return "Easter";
  }

  if (dateObj.getDay() === 5) {
    const daysDiff =
      (easterDateObj.getTime() - dateObj.getTime()) / 1000 / 60 / 60 / 24;
    if (daysDiff <= 3) return "Good Friday";
  }

  return format(dateObj, "eeee");
}

export const ServiceCard = () => {
  const { latestSermon, sermons } = usePublicContext();
  if (!latestSermon) return <></>;
  if (latestSermon.service_data == null && !sermons) return <></>;

  const sermon =
    latestSermon.service_data != null
      ? latestSermon
      : getLatestSermon(sermons!);
  if (!sermon) return <></>;

  const sermonTitle = sermon.title;
  const bulletin = sermon.bulletin;
  const serviceTitle = calculateServiceTitle(sermon);

  const serviceTime =
    prettyDate(sermon.sermon_date.Time, "d MMM yyyy") +
    ", " +
    sermon.service_data.map((x) => x.service.name.split(" ")[0]).join(" & ");
  const serviceSpeaker = sermon.speaker.map((x) => x.name).join(", ");

  return (
    <aside id="service-card">
      <Link
        to={`/get-plugged-in/sermons/${sermon.slug}`}
        className="service-link"
      >
        <div id="service-sunday">{serviceTitle} Service</div>
        <div id="service-time">{serviceTime}</div>
        <div id="service-title">{sermonTitle}</div>
        <div id="service-speaker">{serviceSpeaker}</div>
      </Link>
      <div className="card-btn-wrapper">
        {bulletin.length > 0 && (
          <CardButton link={bulletin}>Bulletin</CardButton>
        )}
        <CardButton link="/get-plugged-in/latest">
          Latest Announcements
        </CardButton>
        <CardButton link="/get-plugged-in/visit-us">Visit Us</CardButton>
      </div>
    </aside>
  );
};

const Frontpage = () => {
  usePageUtils().setTitleAndGroup("", "General");

  return (
    <div id="frontpage">
      <Hero img={FrontpageHeroes} fullHeight width={5342} height={2195}>
        <div id="frontpage-hero">
          <ServiceCard />
        </div>
      </Hero>
      <FrontpageSegment
        title="Ready at His Return"
        buttonTitle="About Us"
        link="/our-community/bfec"
      />
      <FrontpageSegment
        title="A Story Behind Each Salvation"
        buttonTitle="Testimonies"
        link="/our-community/testimonies"
        image={FrontpageTestimoniesImage}
        width={1920}
        height={853}
      />
      <MinistriesSegment />
      <FrontpageSegment
        title={
          <>
            <span className="deemphasise">Where Every Face Is</span> Familiar
          </>
        }
        buttonTitle="Meet the People of Frankel"
        link="/our-community/people"
        image={FrontpagePeopleImage}
        width={1920}
        height={853}
      />
    </div>
  );
};

export default Frontpage;

import { createTheme, ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import { RefTagger } from "react-reftagger";
import { Navigate, Route, Routes } from "react-router-dom";
import { AboutBFEC } from "./AboutUs/AboutBFEC/AboutBFEC";
import MinistriesSelector from "./AboutUs/Ministries/MinistriesSelector";
import { People } from "./AboutUs/People/People";
import { VisitUs } from "./Visit/Visit";
import Frontpage from "./Frontpage/Frontpage";
import { AllImages } from "./ImageLibrary/imageLibrary";
import Footer from "./Navbar/Footer";
import Navbar from "./Navbar/Navbar";
import { PublicContextProvider } from "./PublicContext";
import { WhoIsJesus } from "./WhoIsJesus/WhoIsJesus";
import { WhatsOnContainer } from "./WhatsOn/WhatsOnContainer";
import { AnnouncementPost } from "./WhatsOn/AnnouncementPost";
import { WhatsOnHome } from "./WhatsOn/WhatsOnHome";
import { PostsListing } from "../components/ListingWithSearch/PostsListing/PostsListing";
import { TestimonyPost } from "./Testimonies/TestimonyPost";
import { TestimoniesContainer } from "./Testimonies/TestimoniesContainer";
import { buildSermonsRoute } from "./Sermons/SermonsRoute";
import { BKK } from "./BKK/BKK";
import { Giving } from "./GivingContent";
import { UpcomingMeetings } from "./WhatsOn/UpcomingMeetings";
import { ForMembers } from "./ForMembers/ForMembers";
import { Missions } from "./Missions/Missions";

const theme = createTheme({
  typography: { fontFamily: "Source Sans Pro, serif" },
  palette: {
    primary: {
      light: "#cdb598",
      main: "#264a69",
      dark: "#1b3750",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#bc9c76",
      dark: "#896943",
      contrastText: "#000",
    },
  },
});

const PublicRoutes = () => (
  <Routes>
    <Route path="/">
      <Route path="" element={<Frontpage />} />
    </Route>

    <Route
      path="/sermons"
      element={<Navigate to="/get-plugged-in/sermons" replace />}
    />

    <Route
      path="/give"
      element={<Navigate to="/get-plugged-in/latest#give" replace />}
    />

    <Route path="/get-plugged-in">
      <Route path="" element={<Navigate to="latest" />} />
      <Route path="latest" element={<WhatsOnContainer />}>
        <Route path="" element={<WhatsOnHome />} />
        <Route path="upcoming-meetings" element={<UpcomingMeetings />} />
        <Route path=":slug" element={<AnnouncementPost />} />
      </Route>
      <Route path="members" element={<ForMembers />} />
      <Route path="visit-us" element={<VisitUs />} />
      {buildSermonsRoute()}
    </Route>

    <Route path="/who-is-jesus" element={<WhoIsJesus />} />

    <Route path="/our-community">
      <Route path="" element={<Navigate to="bfec" />} />
      <Route path="bfec" element={<AboutBFEC />} />
      <Route path="people" element={<People />} />
      <Route path="ministries" element={<MinistriesSelector />} />

      <Route path="testimonies" element={<TestimoniesContainer />}>
        <Route path="" element={<PostsListing />} />
        <Route path=":slug" element={<TestimonyPost />} />
      </Route>
    </Route>

    <Route path="/kindergarten" element={<BKK />} />
    <Route path="/giving" element={<Giving />} />
    <Route path="/missions" element={<Missions />} />
  </Routes>
);

export const PublicAppBody = () => (
  <>
    <RefTagger
      bibleVersion="ESV"
      roundCorners={true}
      customStyle={{
        heading: { backgroundColor: "#365c7d", color: "#ffffff" },
      }}
      socialSharing={[]}
      noSearchClassNames={["verse-ref", "card-details-passage"]}
    />
    <ThemeProvider theme={theme}>
      <div id="public-viewport">
        <Navbar />
        <main id="container">
          <PublicRoutes />
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  </>
);

export const PublicApp = () => {
  useEffect(() => {
    // Preload remaining images
    setTimeout(() => {
      AllImages.forEach((pic) => {
        const img = new Image();
        img.src = pic;
      });
    }, 1000);
  }, []);

  return (
    <PublicContextProvider>
      <PublicAppBody />
    </PublicContextProvider>
  );
};
